.projTblTBody tr:nth-child(even){
  background-color: white;
}

.proj-up-mrk-img{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
/* .proj-up-mrk-img:hover{
  color: blue;
}
.proj-up-mrk-img:hover > .MuiSvgIcon-root{
  fill: blue;
} */

.assign-Btn{
  background-color:rgb(38, 166, 154) !important;
  color:white !important;
  font-size:0.7rem !important;
  line-height: 1.2 !important;
}

.profileDialog_tbl tr:nth-child(even){
  background-color: #f7f7f7;
}

.table tr td {
  width: 50%;
}