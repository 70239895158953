.MuiTableCell-stickyHeader {
    padding: 15px !important
}

.MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.MuiTableCell-paddingNone {
    padding: 15px !important;
}

tr {
    background-color: white !important;
}
tr:hover {
    background-color: #f2f2f2 !important;
}
/* .prices-row {
    padding: 0px !important;
} */
.unit-table-menu .MuiPaper-root {
    box-shadow: 5px 0px 5px rgba(1,1,1,0.02) !important
}
/* .status-row {
    padding: 0px !important;
} */
.table-headings {
    min-width: 157px !important;
}