.iframe-container {
    overflow: hidden;
    /* 16:9 aspect ratio */
    padding-top: 56.25%;
    position: relative;
    height: 100vh;
  }
  
  .iframe-container iframe {
   width: 100%;
   height: 100%;
   border: none;
   margin: 0;
   padding: 0;
   position: absolute;
   left: 0;
   top: 0;
  }