.sidebar-main-toggle:focus{
  outline: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #324148;
}

th,
td {
  padding: 16px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

button:focus{
  outline: none !important;
}

.text-error{
  color: red !important;
}

.MuiListItem-root.Mui-selected {
  background: none !important;
  color: rgb(38, 166, 154) !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover{
  background-color: #fff !important;
  color: rgb(38, 166, 154) !important;
}

.MuiListItemText-primary {
  display: flex !important;
}

.MuiTabs-fixed {
  overflow-x: scroll !important;
}

.MuiTabs-fixed::-webkit-scrollbar {
    width: 2px;  /* Remove scrollbar space */
    background: whitesmoke;  /* Optional: just make scrollbar invisible */
    height: 1px;
}

.MuiTabs-fixed::-webkit-scrollbar-thumb {
  background:#324148;
  width: 1px;
  margin-top: 10px;
}


@media only screen and (min-width: 767px) {
  .sidebar-mobile-toggler {
    display: none !important;
  }
}


.MuiTab-wrapper {
  flex-direction: row-reverse !important;
}

.MuiTabs-indicator {
  height: 5px !important;
  background-color: #26a69a !important;
}

.MuiTabs-fixed {
  scrollbar-width: none;
  scrollbar-color: #b5d8e9 whitesmoke;
}

.sidebar:not(.sidebar-component) {
  z-index: 1001 !important;
}

table {
  border: none !important;
}

table:hover {
  background: none !important;
  background-color: none !important;
}

.MuiChip-deleteIcon {
  color: crimson !important;
}

.contacts-table-rows {
  background-color: white !important;
}
.contacts-table-rows:hover {
  background-color: inherit !important;
}

.separated-horizontally > *:not(:first-child) {
  border-left-width: 1px;
  border-left-style: solid;
}

.share-modal .MuiPaper-root{
  min-width: 240px;
}
.share-modal .MuiPaper-root .share .share-btn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  font-size: 15px;
}
.share-modal .MuiPaper-root .share .share-btn .social-group{
  display: flex;
}

.date-picker{
  border-width: 0px 0px 1px 0px;
  border-radius: 0;
  border-color: #0000006b;
  width: 200px;
}
.date-picker:focus{
  outline: none;
}